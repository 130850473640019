.headerContent {
    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: center;

}

.titleName {
    color: white;
    font-size: 7rem;
    font-family: 'Lato', sans-serif;
    font-weight: 600
}


.navbar {
    background-color: #3f3f3f;
    z-index: 2;
}

.navbarFlex {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-content: center;
    height: 100%;
}

.navbarItem {
    color: white;
    flex-grow: 2;
    text-align: center;
    font-size: 1.3em;
    height: 100%;
}

.navbarItem:hover {
    background-color: #2e2e2e;
    cursor: pointer;
}

.navbarTitle {
    margin-top: 7px;
}

.sticky + .page {
    padding-top: var(--heightnavbar);
}

.fade-in {
    animation-name: fade-in;
    animation-duration: 0.3s;
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    animation-fill-mode: both;
}

.PageContent {
    animation-name: fade-in;
    animation-duration: 0.3s;
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    animation-fill-mode: both;
}


.page {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.sticky {
    position: fixed;
    top: 0;
    width: 100%;
  }
  

@media (orientation: portrait) {
    .navbarItem {
        font-size: 1rem;
    }
    .titleName {
        font-size: 1.5rem;
    }
}


