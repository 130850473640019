
.ShipulatorApp {
    background-color: #333C40;
    width: 100%;
}

.ShipulatorApp > .PageContent {
    min-height: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;

}

.ShipTitle {
    color: white;
    border-bottom: rgb(54, 160, 206) solid 2px;
    padding: 4px;
    font-size: 7rem;
}

.ShipDesc {
    color: white;
    font-size: 1.5rem;
    width: 800px;
}

.ShipDownloadButton {
    background-color: rgb(54, 160, 206);
    font-size: 45px;
    border-radius: 5px;
    padding: 0 20px;
    padding-bottom: 15px;
    padding-top: 10px;
    color: white;
    text-decoration: none;
    font-weight: 600;
}

.ShipDownloadButton:hover {
    cursor: pointer;
    background-color: rgb(52, 151, 192);
}

@media (orientation: portrait) {
    .ShipTitle {
        font-size: 55px;
    }
    
    .ShipDesc {
        font-size: 20px;
        max-width: 90%;
    }

    .ShipDownloadButton {
        font-size: 35px;
        padding: 0 20px;
        padding-bottom: 15px;
        padding-top: 10px;
    }


}