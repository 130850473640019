.DayDreamsApp {
    width: 100%;
    min-height: 100%;
    background-color: rgb(255, 239, 241);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
}

.ddtitle {
    color: black;
    font-size: 7em;
}

.ddDescription {
    width: 800px;
    font-size: 1.5rem;
}

.DDGrid {
    display: flex;
    flex-wrap: wrap; 
    margin: 10%;
}


.DayDream {
    width: 150px;
    height: 150px;
    text-align: center;
    background-color: rgb(255, 119, 135);
    margin: 15px;
    display: flex;
    flex-direction: column;
    text-decoration: none;
}

.DDBuffer {
    flex-grow: 9;
}

.DDContent {
    transition-duration: 0.4s;
    transition-property: flex-grow, height;
    flex-grow: 0;
    height: 0px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    overflow: hidden;
    background-color: rgba(0,0,0,0.5);
    color: white;
}

.DayDream:hover {
    cursor: pointer;
}

.DayDream:hover > .DDContent {
    transition-duration: 0.4s;
    transition-property: all;
    flex-grow: 9;
    height: 100%;
}


.DDTitle {
    background-color: #3f3f3f;
    color: white;
    padding-bottom: 2px;
}


@media (orientation: portrait) {
    .DayDream {
        width: 100px;
        height: 100px;
    }
    .DDTitle {
        font-size: 0.7rem;
    }
    .DDContent {
        font-size: 0.7rem;
        flex-grow: 9;
        height: 100%;
    }
    .ddtitle {
        font-size: 2.4rem;
    }
    .ddDescription {
        font-size: 1.2rem;
        max-width: 90%;
    }
}