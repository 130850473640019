.text {
    width: 800px;
}

.Paragraph {
    margin: 25px;
}

.logo {
    margin: 25px;
    margin-bottom: 0px;
    margin-top: 50px;
}

.Title {
    font-size: 4em;
}

.HomeApp {
    background-color: rgb(235, 235, 235);
    min-height: 100%; 
    width: 100%;
    display: flex;
    justify-content: center;
}

.HomeApp > .PageContent {
    max-width: 800px;
}

.BlackLink {
    color: black;
}

.BlackLink:visited {
    color: black;
}

.icon {
    width: 64px;
    height: 64px;
}