
.HuetifyApp {
    background-color: #212121;
    width: 100%;
}

.HuetifyApp > .PageContent {
    min-height: 100%;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Title {
    font-size: 85px;
}

.Subtitle {
    text-align: center;
    font-size: 35px;
}

.DownloadButton {
    background-color: #388E3C;
    font-size: 45px;
    border-radius: 5px;
    padding: 0 20px;
    padding-bottom: 15px;
    padding-top: 10px;
    color: white;
    text-decoration: none;
}

.DownloadButton:hover {
    background-color: rgb(50, 122, 54);
    cursor: pointer;
}

.antivirus {
    color: #a5a5a5;
}

.footer {
    flex-shrink: 0;
    height: 45px;
}



footer,
a {
    color: #a5a5a5;
}

#corner0 {
    margin-bottom: -150px;
    margin-left: -40px;
}

#corner1 {
    transform: rotate(180deg);
    margin-top: -120px;
    margin-right: -40px;
}

#buffer0 { flex-grow: 0.7; }
#buffer1 { flex-grow: 1; }
#buffer2 { flex-grow: 1; }
#buffer3 { flex-grow: 2; }


@media (orientation: portrait) {
    .Title {
        font-size: 55px;
    }
    
    .Subtitle {
        font-size: 20px;
    }

    .DownloadButton {
        font-size: 35px;
        padding: 0 20px;
        padding-bottom: 15px;
        padding-top: 10px;
    }

    .antivirus {
        font-size: 10px;
        width: 60%;
    }

    .footer {
        font-size: 12px;
    }


    #corner0 {
        margin-bottom: -140px;
        margin-left: 50px;
    }
    
    #corner1 {
        transform: rotate(180deg);
        margin-top: -130px;
        margin-right: 50px;
    }
}
